import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Segeln in der Karibik" />
    <div style={{ width: `100%`, marginBottom: `1.45rem` }}>
      <Image />
    </div>

    <div className="container mx-auto  px-8">
      <div className="w-full py-6">  
        <h1
          style={{ 
            margin: 0,
            fontFamily: `'Rufina', serif`,
            fontSize: `110px`,
            lineHeight: `100px`,
            letterSpacing: `0px`,
          }}
        >Segeln in der Karibik</h1>
        <h3
          style={{ 
            margin: 0,
            fontFamily: `'Rufina', serif`,
            fontStyle: `italic`,
            fontSize: `54px`,
            lineHeight: `72px`,
            letterSpacing: `0px`,
          }}>Büchertips</h3>
        <Link
          to="/buecher/"
          className="bg-blue-500 hover:bg-blue-700 text-white uppercase font-bold py-4 px-6 rounded"
          style={{ 
            margin: 0,
            fontFamily: `'Oxygen', sans-serif`,
            fontSize: `18px`,
            lineHeight: `48px`,
            letterSpacing: `0px`,
          }}
        >Unsere Büchertips</Link>
      </div>
    </div>
  </Layout>
)

export default IndexPage
